import React from "react"

// Styling
import colors from "../styles/colors"

const PlusIcon: React.FC<{ color: string }> = ({
  color = colors.black,
  ...props
}) => (
  <svg viewBox="0 0 360 360" {...props} fill={color}>
    <path d="M357 204H204v153h-51V204H0v-51h153V0h51v153h153v51z" />
  </svg>
)

export default PlusIcon
