import React from "react"
import { graphql } from "gatsby"

// Components
import PageLayout from "../components/PageLayout"
import PrincipleBlock from "../components/PrincipleBlock"

// Types
import { IImage } from "../types"

export interface IPrinciple {
  name: string
  description: string
  image: IImage
  levels: [{ level: string; description: string; image: IImage }]
}

interface IDNA {
  id: string
  name: string
  principles: IPrinciple[]
}

interface IProps {
  dna: IDNA
}

const TeamDNAPage = ({ dna }: IProps) => {
  return (
    <PageLayout seoTitle={"Jungle Formula"}>
      <div>
        {dna.principles.map((principle, index) => (
          <PrincipleBlock
            key={principle.name}
            principle={principle}
            index={index}
          />
        ))}
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allLevelQualities: allPrismicLevelQuality {
      nodes {
        ...LevelQualities
      }
    }
  }
`

const ConnectedTeamDNAPage = ({ data }: any) => {
  if (!data.allLevelQualities) {
    return null
  }

  const formattedDNA = data.allLevelQualities.nodes.map((item: any) => ({
    id: item.id,
    name: item.data.name,
    principles: item.data.dnaPrinciples.map((principle: any) => ({
      name: principle.dnaPrinciple.name,
      description: principle.dnaPrinciple.description.text,
      image: principle.dnaPrinciple.image,
      levels: principle.dnaPrinciplePerLevel.map((dnaLevelItem: any) => ({
        level:
          dnaLevelItem.level && dnaLevelItem.level.document
            ? dnaLevelItem.level.document.length &&
              dnaLevelItem.level.document[0].data.name
            : "",
        description: dnaLevelItem.description.text,
      })),
    })),
  }))

  return <TeamDNAPage dna={formattedDNA.length && formattedDNA[0]} />
}

export default ConnectedTeamDNAPage
