import React from "react"

// Styling
import colors from "../styles/colors"

const MinusIcon: React.FC<{ color: string }> = ({
  color = colors.black,
  ...props
}) => (
  <svg viewBox="0 0 298.667 298.667" {...props} fill={color}>
    <path d="M0 128h298.667v42.667H0z" />
  </svg>
)

export default MinusIcon
