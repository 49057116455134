import React, { useState, useRef, useLayoutEffect } from "react"
import styled from "styled-components"

// Styles
import colors from "../styles/colors"
import svgStyles from "../styles/svgStyles"
import { mq } from "../styles/utils/mq"

// Components
import MinusIcon from "../icons/MinusIcon"
import PlusIcon from "../icons/PlusIcon"

// Types
import { IPrinciple } from "../pages"

interface IPrincipleSectionComponent {
  principle: IPrinciple
  index: number
}

const PrincipleBlock: React.FC<IPrincipleSectionComponent> = ({
  index,
  principle,
}) => {
  const [levelIsHidden, setLevelToHidden] = useState(true)
  const [animateHeight, setNewHeight] = useState(0)
  const myRef = useRef<HTMLDivElement>(null)

  const toggleLevels = () => {
    if (myRef.current !== undefined) {
      if (
        myRef !== null &&
        myRef.current !== null &&
        myRef.current.firstElementChild !== null &&
        myRef.current.firstElementChild.clientHeight !== undefined
      ) {
        // Toggle the div to open / close
        setLevelToHidden(prevState => !prevState)

        // Get the height and store it in the state this way it will get animated
        setNewHeight(myRef.current.firstElementChild.clientHeight)
      }
    }
  }

  const updateSize = () => {
    // Close all the opened containers
    setNewHeight(0)
    // Make sure the labels are reset
    setLevelToHidden(true)
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return (
    <PrincipleSection>
      <PrincipleFlex>
        <FlexBlock>
          <PrincipleTitle>
            {/* Makes sure all numbers start with a dash and numbers below 10 have a 0 in front of them */}
            &#8212;{("0" + (index + 1)).slice(-2)}
            <SecondLineTitle>{principle.name}</SecondLineTitle>
          </PrincipleTitle>
          <PrincipleDescription>{principle.description}</PrincipleDescription>

          <Collapsable isOpen={levelIsHidden} onClick={toggleLevels}>
            <PlusIcon color="black" />
            <IconText>Applied to Jr, Mr, Sr. & Sr+.</IconText>
          </Collapsable>

          <Collapsable isOpen={!levelIsHidden} onClick={toggleLevels}>
            <MinusIcon color="black" />
            <IconText>Collapse</IconText>
          </Collapsable>
        </FlexBlock>
        <FlexBlock>
          <PrincipleImage src={principle.image.url} alt={principle.image.alt} />
        </FlexBlock>
      </PrincipleFlex>
      <AnimateBlock
        ref={myRef}
        isOpen={!levelIsHidden}
        animateHeight={animateHeight}
      >
        <HorizontalList>
          {principle.levels.map(level => (
            <li key={level.level}>
              <strong>{level.level}</strong>
              <p>{level.description}</p>
            </li>
          ))}
        </HorizontalList>
      </AnimateBlock>
    </PrincipleSection>
  )
}

export default PrincipleBlock

const PrincipleFlex = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${mq.from.M`
    flex-direction: row;
  `}
`

const FlexBlock = styled.div`
  width: 100%;
  margin: auto;
  padding: 0;

  &:nth-child(1) {
    padding: 0 15px 0 0;
  }

  &:nth-child(2) {
    padding: 0 0 0 15px;
  }

  ${mq.from.M`
    width: 50%;
  `}
`

const PrincipleSection = styled.div`
  display: block;
  padding: 3em 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.black};
  }

  ${mq.from.M`
    &:nth-child(even) ${PrincipleFlex} {
      flex-direction: row-reverse;
    }
  `}

  /* Give padding to the odd blocks (row reverse) */
  ${mq.from.M`
    &:nth-child(even) ${FlexBlock}:nth-child(1) {
      padding: 0 0 0 15px;
    }
  `}

  ${mq.from.M`
    &:nth-child(even) ${FlexBlock}:nth-child(2) {
      padding: 0 15px 0 0;
    }
  `}
`

const PrincipleImage = styled.img`
  object-fit: contain;
  width: 100%;
  margin: auto;
`

const IconText = styled.span`
  font-weight: bolder;
`

interface ICollape {
  isOpen: boolean
}

interface IAnimateBlock extends ICollape {
  animateHeight: number
}

const AnimateBlock = styled.div<IAnimateBlock>`
  transition: 1s;
  overflow: hidden;
  display: block;
  ${({ isOpen, animateHeight }) =>
    isOpen ? `max-height: ${animateHeight}px` : `max-height: 0;`};
`

const HorizontalList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;

  li {
    margin: 0;
    padding: 0;
    :first-child {
      padding-left: 0;
    }
    :last-child {
      padding-right: 0;
    }

    p:first-child {
      margin-top: 0;
    }
  }

  ${mq.from.L`
    flex-direction: row;

    li {
      padding: 15px;
    }
  `}
`

const PrincipleTitle = styled.h3`
  ::before {
    /* Keeps the dash nicely in front */
    content: "";
  }

  ::first-letter {
    font-size: 1.8em;
    vertical-align: text-top;
  }

  color: ${colors.accent1};
  font-size: 1.5em;
  line-height: 1;
`

const PrincipleDescription = styled.p`
  padding: 0;
`

const SecondLineTitle = styled.span`
  display: block;
  padding: 25px 0;
  font-size: 2em;
`

const Collapsable = styled.div<ICollape>`
  margin: 25px 0;
  cursor: pointer;
  width: fit-content;

  ${mq.from.L`
    margin: 25px 0;
  `}

  ${({ isOpen }) => (isOpen ? `display: block` : `display: none`)};

  svg {
    ${svgStyles.regularSVG};
  }

  :hover svg {
    fill: ${colors.accent1};
  }
`
